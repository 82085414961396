export interface Role {
    roleId?: number;
    name?: string;
    isDeleted?: boolean;
}

export enum RoleConstants {
    BILD_ADMIN = 'BILD_ADMIN',
    BILD_OPERADOR = 'BILD_OPERADOR', 
    BILD_ENCARGADO = 'BILD_ENCARGADO', 
    BILD_VENTAS = 'BILD_VENTAS',
    CLIENTE_ADMIN = 'CLIENTE_ADMIN',
    CLIENTE_OPERADOR = 'CLIENTE_OPERADOR',
}

export type RoleAction = 'edit' | 'delete' | 'account';

export type PermissionType = {
    edit: string[];
    delete: string[];
    account: string[];
  };
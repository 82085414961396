<div class="not-found-container">
    <div class="left-panel">
      <h1>404</h1>
      <p>Página no encontrada</p>
      <p>No pudimos encontrar la página que estás buscando.</p>
      <button class="back-button" routerLink="/">Volver al Inicio</button>
    </div>
    <div class="right-panel">
      <div class="logo-container">
        <img src="assets/Bild-Portal.svg" alt="Logo de la empresa" class="logo" />
        <div class="disconnect-icon">
        </div>
      </div>
    </div>
  </div>
  
  
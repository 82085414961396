import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountDropdown } from '../domain/accountDropdown.model';
import { Account } from '../domain/account.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private apiUrl = environment.apiUrl + '/api/v1/accounts';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getAllDropdownAccounts(account?: Account): Observable<AccountDropdown[]> {
    var url = `${this.apiUrl}/dropdown`;
    if (account) {
      url = url + `/${account.accountId}`;
    }
    return this.http.get<AccountDropdown[]>(url);
  }

  createAccount(account: Account): Observable<Account> {
    return this.http.post<Account>(this.apiUrl, account, this.httpOptions);
  }

  updateAccount(account: Account): Observable<Account> {
    const url = `${this.apiUrl}/${account.accountId}`;
    return this.http.put<Account>(url, account, this.httpOptions);
  }

  getAllAccounts(): Observable<Account[]> {
    return this.http.get<Account[]>(this.apiUrl);
  }

  deleteAccount(account: Account): Observable<Account> {
    const url = `${this.apiUrl}/${account.accountId}`;
    return this.http.delete<Account>(url);
  }

  deleteAccounts(userIds: number[]): Observable<Account> {
    return this.http.delete<Account>(`${this.apiUrl}/delete`, { ...this.httpOptions, body: userIds });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../domain/user.model';
import { Account } from '../domain/account.model';
import { RoleConstants, RoleAction, PermissionType } from '../domain/role.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenKey = 'jwtToken';
  private apiUrl = environment.apiUrl + '/api/v1/auth';
  private userKey = 'userDetails';
  private user: User = {};

  constructor(private http: HttpClient, private router: Router) { }

  login(credentials: { username: string; password: string }): Observable<any> {
    return this.http.post(`${this.apiUrl}/login`, credentials, {});
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  setUserDetails(user: any): void {
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  getUserDetails(): any {
    if (!!this.user) {
      const user = localStorage.getItem(this.userKey);
      if (user) {
        this.user = JSON.parse(user);
        return this.user;
      } else {
       this.router.navigate(['/login']);
       return;
      }
    }
    return this.user;
  }

  getUserRole(): string {
    const user = this.getUserDetails();
    return user.role?.name || '';
  }

  isBildAdmin(): boolean {
    return this.getUserRole() === RoleConstants.BILD_ADMIN;
  }

  getUserAccount(): Account {
    const userString = localStorage.getItem(this.userKey);
    const user: User = JSON.parse(userString || '{}') as User;
    return user.account || {};
  }

  canAccess(requiredRoles: string[]): boolean {
    const userRole = this.getUserRole();
    return requiredRoles.includes(userRole);
  }

  canAccessAction(view: string, action: RoleAction): boolean {
    const userRole: string = this.getUserRole();
    const rolePermissions: { [key: string]: PermissionType } = {
      accounts: {
        edit: [RoleConstants.BILD_VENTAS, RoleConstants.BILD_ADMIN],
        delete: [RoleConstants.BILD_VENTAS, RoleConstants.BILD_ADMIN],
        account: []
      },
      users: {
        edit: [],
        delete: [],
        account: []
      },
      simpleLabels: {
        edit: [],
        delete: [],
        account: []
      },
      palletLabels: {
        edit: [],
        delete: [],
        account: []
      },
      inventory: {
        edit: [RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO],
        delete: [RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO],
        account: [RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO, RoleConstants.BILD_VENTAS]
      },
      
    };
    return rolePermissions[view][action].includes(userRole);
  }

  isLoggedIn(): boolean {
    return this.getToken() !== null;
  }

  logout(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.userKey);
    this.user = {};
    this.router.navigate(['/login']);
  }
}

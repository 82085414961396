import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './pages/users/users.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { SimpleLabelsComponent } from './pages/simple-labels/simple-labels.component';
import { PalletLabelsComponent } from './pages/pallet-labels/pallet-labels.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { CreateOrderComponent } from './pages/create-order/create-order.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { roleGuard } from './../guard/role.guard';
import { RoleConstants } from './../domain/role.model';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'home', component: HomeComponent,
    children: [
      {
        path: 'users',
        canActivate: [roleGuard([RoleConstants.BILD_ADMIN, RoleConstants.BILD_ENCARGADO])],
        component: UsersComponent
      },
      {
        path: 'accounts',
        canActivate: [roleGuard([RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO, RoleConstants.BILD_VENTAS])],
        component: AccountsComponent
      },
      {
        path: 'simpleLabels',
        canActivate: [roleGuard([RoleConstants.BILD_ADMIN, RoleConstants.BILD_ENCARGADO])],
        component: SimpleLabelsComponent
      },
      {
        path: 'palletLabels',
        canActivate: [roleGuard([RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO])],
        component: PalletLabelsComponent
      },
      {
        path: 'orders',
        canActivate: [roleGuard([RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO, RoleConstants.BILD_VENTAS])],
        component: OrdersComponent
      },
      {
        path: 'order/create',
        canActivate: [roleGuard([RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO, RoleConstants.BILD_VENTAS])],
        component: CreateOrderComponent
      },
      {
        path: 'inventory',
        canActivate: [roleGuard([RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO, RoleConstants.BILD_VENTAS, RoleConstants.CLIENTE_ADMIN, RoleConstants.CLIENTE_OPERADOR])],
        component: InventoryComponent
      },
    ]
  },
  //{ path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/home/inventory', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

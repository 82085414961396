<h2>Etiqueta simple</h2>
<!--Table-->
<div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">
            <p-dropdown [options]="simple_labels_array" [(ngModel)]="selectedWarehouse" placeholder="Selecciona una bodega"
                optionLabel="warehouse" autofocus></p-dropdown>
        </ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple label="Generar Etiquetas" icon="pi pi-plus" class="p-button-primary mr-2"
                (click)="onCreateNewLabels()" [disabled]="!selectedWarehouse.simpleLabelId"></button>
            <button pButton pRipple label="Imprimir etiquetas" icon="pi pi-print" class="p-button-primary"
                (click)="onPrintLabels()" [disabled]="!selectedWarehouse.simpleLabelId"></button>
        </ng-template>
    </p-toolbar>

    <p-table #dt [value]="simple_labels_array" [rows]="10" [paginator]="true" [tableStyle]="{ 'min-width': '75rem' }"
        [rowHover]="true" dataKey="simple_label_id" styleClass="p-datatable-gridlines"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <h5 class="m-0">Administrar Etiquetas de Bodegas</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text"
                        (input)="dt.filter($any($event.target)?.value, 'warehouse', 'contains')"
                        [value]="$any(dt.filters['warehouse'])?.value" placeholder="Buscar por bodega"
                        class="p-column-filter">
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="warehouse" style="min-width:15rem">Bodega <p-sortIcon
                        field="warehouse"></p-sortIcon></th>
                <th>Contador</th>
                <th>Ultimo usuario</th>
                <th>Ultima modificación</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.warehouse }}</td>
                <td>{{ item.counter }}</td>
                <td>{{ item.lastUser }}</td>
                <td>{{ item.updateTime | date:'medium' }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">En total hay {{ simple_labels_array ?
                simple_labels_array.length : 0
                }} bodegas.</div>
        </ng-template>
    </p-table>
</div>

<!-- Dialog new Label -->
<p-dialog [(visible)]="labelDialog" [style]="{ width: '420px' }" header="Generar Etiquetas Simples" [modal]="true"
    styleClass="p-fluid" (onHide)="hideNewLabelDialog()">
    <ng-template pTemplate="content">
        <div class="flex flex-column align-items-center w-full gap-3 surface-border">
            <p>Seleccione el número de etiquetas a imprimir</p>
            <p-inputNumber [(ngModel)]="generateLabelsNumber" [showButtons]="true" buttonLayout="horizontal"
                spinnerMode="horizontal" inputId="horizontal" decrementButtonClass="p-button-secondary"
                incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus" [min]="1" [max]="1000" (ngModelChange)="onLabelQuantityChange()"></p-inputNumber>

        </div>
        <div>
            <p>La bodega tiene {{ selectedWarehouse.counter }} etiquetas creadas.</p>
            <p>Se imprimirá de la etiqueta {{ printLabelsFrom }} a la {{ printLabelsTo }}.</p>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="hideNewLabelDialog()"></button>
        <button pButton pRipple label="Generar etiquetas" icon="pi pi-check" class="p-button-text"
            (click)="generateNewLabels()"></button>
    </ng-template>
</p-dialog>

<!-- Dialog print labels -->
<p-dialog [(visible)]="printDialog" [style]="{ width: '420px' }" header="Imprimir Etiquetas Simples" [modal]="true"
    styleClass="p-fluid" (onHide)="hidePrintLabelsDialog()">
    <ng-template pTemplate="content">

        <form [formGroup]="printLabelForm">
            <p>Seleccione el rango de etiquetas a imprimir</p>
            <div class="field">
                <label for="printLabelsFrom">De</label>
                <p-inputNumber formControlName="printLabelsFrom" [(ngModel)]="printLabelsFrom" [showButtons]="true"
                    buttonLayout="horizontal" spinnerMode="horizontal" inputId="horizontal"
                    decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" [min]="1"
                    [max]="labelsCounter"></p-inputNumber>
                <small class="p-error"
                    *ngIf="printLabelSubmitted && printLabelForm.controls['printLabelsFrom'].errors?.['required']">Campo
                    requerido.</small>
            </div>

            <div class="field">
                <label for="printLabelsTo">Hasta</label>
                <p-inputNumber formControlName="printLabelsTo" [(ngModel)]="printLabelsTo" [showButtons]="true"
                    buttonLayout="horizontal" spinnerMode="horizontal" inputId="horizontal"
                    decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" [min]="1"
                    [max]="labelsCounter"></p-inputNumber>
                <small class="p-error"
                    *ngIf="printLabelSubmitted && printLabelForm.controls['printLabelsTo'].errors?.['required']">Campo
                    requerido.</small>
                <small class="p-error" *ngIf="printLabelForm.hasError('invalidRange')">El número de fin debe ser mayor
                    que el número de inicio.</small>
                <small class="p-error" *ngIf="printLabelForm.hasError('maxExceeded')">Solo se pueden imprimir hasta 100
                    etiquetas.</small>
            </div>

            <div *ngIf="!printLabelForm.hasError('invalidRange')">
                <p class="font-semibold">Se imprimiran {{ (printLabelsTo+1) - printLabelsFrom }} etiquetas</p>
            </div>
        </form>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="hidePrintLabelsDialog()"></button>
        <button [disabled]="!this.printLabelForm.valid" pButton pRipple label="Imprimir etiquetas" icon="pi pi-check"
            class="p-button-text" (click)="printLabels()"></button>
    </ng-template>
</p-dialog>

<!-- confirm dialog-->
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>